<template>
    <div class="jargon-dialog-compontent">
        <el-dialog :title="$t('component.JargonDialog_title')" :visible.sync="show" @close="jargonDialogCloseEvent" width="950px" top="20px">
            <div class="alert-warn-block text-center">{{ $t('component.JargonDialog_tips') }}</div>
            <div class="mian-table-content">
                <div class="flex flex-justify-between margin-b-20">
                    <div class="flex">
                        <el-tooltip :content="$t('component.JargonDialog_tooltip_content')" placement="top">
                            <upload-file ref="uploadTermNode" :title="$t('component.JargonDialog_button_upload_title')" btn-size="mini" accept=".xls,.xlsx,.tbx" @success="uploadTermSuccess"></upload-file>
                        </el-tooltip>
                        <el-button plain round size="mini" :loading="downloadTemplateBtnLoading" @click="downloadTemplateEvent" class="margin-l-10">{{ $t('component.JargonDialog_button_download_template') }}</el-button>
                    </div>
                    <div class="search-input">
                        <el-input :placeholder="$t('component.JargonDialog_input_search_placeholder')" clearable v-model="kw" suffix-icon="el-icon-search" size="mini" round style="width:340px;" @input="searchInputEvent"></el-input>
                    </div>
                </div>
                
                <div class="table-content">
                    <el-table :data="tableData" size="mini" v-loading="tableLoading">
                        <el-table-column :label="$t('component.JargonDialog_table_column_index_label')" type="index" :index="index=>{return index===0?'-':index}" width="50"></el-table-column>
                        <el-table-column :label="$t('component.JargonDialog_table_column_source_label')">
                            <template slot-scope="scope">
                                <div :lang="language.source">
                                    <div class="table-input" v-if="scope.row.edit === true">
                                        <el-input size="small" v-model="editOriginalValue" show-word-limit maxlength="500" :placeholder="$t('component.JargonDialog_input_source_placeholder')"></el-input>
                                    </div>
                                    <template v-else>
                                        <span v-if="scope.row.termDetailId">{{scope.row.source}}</span>
                                        <div class="table-input" v-else>
                                            <el-input size="small" v-model="newOriginalValue" show-word-limit maxlength="500" :placeholder="$t('component.JargonDialog_input_source_placeholder')"></el-input>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('component.JargonDialog_table_column_target_label')">
                            <template slot-scope="scope">
                                <div :lang="language.target">
                                    <div class="table-input" v-if="scope.row.edit === true">
                                        <el-input size="small" v-model="editTargetValue" show-word-limit maxlength="500" :placeholder="$t('component.JargonDialog_input_target_placeholder')"></el-input>
                                    </div>
                                    <template v-else>
                                        <span v-if="scope.row.termDetailId">{{scope.row.target}}</span>
                                        <div class="table-input" v-else>
                                            <el-input size="small" v-model="newTargetValue" show-word-limit maxlength="500" :placeholder="$t('component.JargonDialog_input_target_placeholder')"></el-input>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('component.JargonDialog_table_column_remark_label')">
                            <template slot-scope="scope">
                                <div>
                                    <div class="table-input" v-if="scope.row.edit === true">
                                        <el-input size="small" v-model="editRemarkValue" show-word-limit maxlength="100" :placeholder="$t('component.JargonDialog_input_remark_placeholder')"></el-input>
                                    </div>
                                    <template v-else>
                                        <span v-if="scope.row.termDetailId">{{scope.row.description}}</span>
                                        <div class="table-input" v-else>
                                            <el-input size="small" v-model="newRemarkValue" show-word-limit maxlength="100" :placeholder="$t('component.JargonDialog_input_remark_placeholder')"></el-input>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('component.JargonDialog_table_column_action_label')" :width="source?130:100">
                            <template slot-scope="scope">
                                <template v-if="scope.row.edit === true">
                                    <el-button type="text" size="small" :loading="saveEditBtnLoading" @click="saveEditEvent(scope)">{{ $t('component.JargonDialog_button_save') }}</el-button>
                                    <el-button type="text" size="small" @click="cancelEditEvent(scope)">{{ $t('component.JargonDialog_button_cancel') }}</el-button>
                                </template>
                                <template v-else>
                                    <template v-if="scope.row.termDetailId">
                                        <el-button type="text" size="small" @click="editEvent(scope)">{{ $t('component.JargonDialog_button_edit') }}</el-button>
                                        <el-button type="text" size="small" @click="reTranslateEvent(scope.row)" v-if="source">{{ $t('component.JargonDialog_button_reTranslate') }}</el-button>
                                        <el-popconfirm :title="$t('component.JargonDialog_button_delete_confirm_title')" hide-icon :cancel-button-type="''" @confirm="deleteEvent(scope.row)">
                                            <el-button slot="reference" size="small" type="text" style="margin-left:15px;" class="delete-btn">{{ $t('component.JargonDialog_button_delete') }}</el-button>
                                        </el-popconfirm>
                                    </template>
                                    <el-button type="text" size="small" :loading="saveAddBtnLoading" @click="saveAddEvent" v-else>{{ $t('component.JargonDialog_button_save') }}</el-button>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pagination-content" v-if="tableData.length > 0 && total > pageSize">
                        <el-pagination 
                            background 
                            @current-change="handleCurrentChange"
                            layout="total, prev, pager, next"
                            :page-size="pageSize"
                            :current-page="pageNumber"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import cookie from 'vue-cookies';
import fileDownload from 'js-file-download';
import { jargonApi,termApi } from '@/utils/api';
import UploadFile from '@/components/UploadOssButton';
export default {
    components: { UploadFile, },
    props:{
        doc:{
            type: Object,
            default: {},
        },
        language:{
            type:Object,
            default:null
        },
        source:{
            type:String,
            default:'',
        }
    },
    data(){
        return{
            show:false,
            uploadUrl:jargonApi.batchImport,
            uploadHeaders:{
                'Authorization':cookie.get("token")
            },
            uploadData:{},
            uploadBtnLoading:false,
            downloadTemplateBtnLoading:false,
            kw:'',
            tableLoading:false,
            newOriginalValue:'',
            newTargetValue:'',
            newRemarkValue: '',
            editTargetValue:'',
            editOriginalValue:'',
            editRemarkValue: '',
            tableData:[{termDetailId:null,source:'',target:'',description:'',edit:false}],
            saveAddBtnLoading:false,
            saveEditBtnLoading:false,
            searchInputTimer:null,
            pageNumber:1,
            pageSize:10,
            total:0,
        }
    },
    methods:{
        initPage(){
            let url= termApi.customTermsDetail;
            let postData = {
                documentId: this.doc.docId,
                pageNo: this.pageNumber,
                pageSize: this.pageSize,
            };
            if (this.kw) {
                postData.keyword = this.kw;
            }
            this.tableLoading=true;
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    let tmpList=res.data.records.map(item=>{
                        return {edit:false,...item};
                    })
                    this.tableData=[].concat([{termDetailId:null,source:'',target:'',description:'',edit:false}],tmpList);
                    this.total = res.data.total;
                }
            }).finally(()=>{
                this.tableLoading = false;
            })
        },
        jargonDialogCloseEvent(){
            this.kw = '';
            this.pageNumber = 1;
        },
        handleCurrentChange(num){
            this.pageNumber = num;
            this.initPage();
        },
        downloadTemplateEvent(){
            let downMsg = this.$message({
                message: this.$t('component.JargonDialog_downloading_template_message'),
                type: 'info',
                iconClass: 'el-icon-loading',
                duration: 0,
            });
            this.downloadTemplateBtnLoading = true;
            this.$ajax.get(jargonApi.downloadTemplate).then(res=>{
                if(res.status === 200){
                    if(res.data){
                        window.open(res.data);
                    }else {
                        this.$message.error(this.$t('component.JargonDialog_download_fail_message'));
                    }
                }
            }).finally(()=>{
                this.downloadTemplateBtnLoading = false;
                downMsg.close();
            })
        },
        // 术语定制文件上传成功回调
        uploadTermSuccess(){
            let url = termApi.appendCustomTermFile;
            let docs = this.$refs.uploadTermNode.getOssFiles();
            if(docs.length === 0){
                this.$message.error(this.$t('component.JargonDialog_term_upload_verification_message'));
                return false;
            }
            let postData = {
                documentId: this.doc.docId,
                docs,
            }
            // console.log('success::', postData);
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200){
                    this.$message.success(this.$t('component.JargonDialog_term_upload_success_message'));
                    this.initPage();
                }
            })
        },
        searchInputEvent(value){
            if(this.searchInputTimer){
                clearTimeout(this.searchInputTimer);
            }
            this.pageNumber = 1;
            this.searchInputTimer = setTimeout(this.initPage,500);
        },
        editEvent(scope){
            let row=scope.row;
            this.editOriginalValue = row.source;
            this.editTargetValue = row.target;
            this.editRemarkValue = row.description;
            this.tableData[scope.$index]['edit'] = true;
        },
        cancelEditEvent(scope){
            this.tableData[scope.$index]['edit'] = false;
        },
        saveAddEvent(){
            let url=termApi.saveAddCustomTerm;
            if(!this.newOriginalValue){
                this.$message.error(this.$t('component.JargonDialog_term_source_verification_message'));
                return;
            }
            if(!this.newTargetValue){
                this.$message.error(this.$t('component.JargonDialog_term_target_verification_message'));
                return;
            }
            let params = {
                documentId: this.doc.docId,
                source:this.newOriginalValue,
                target:this.newTargetValue,
                description: this.newRemarkValue,
            };
            if(this.termId){
                params.termId = this.termId;
            }else{
                if(this.language){
                    params.sourceLang = this.language.source;
                    params.targetLang = this.language.target;
                }
            }
            this.saveAddBtnLoading = true;
            this.$ajax.post(url,params).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('component.JargonDialog_save_success_message'));
                    this.newOriginalValue = '';
                    this.newTargetValue = '';
                    this.newRemarkValue = '';
                    if(res.data.termId){
                        this.$emit('update',res.data.termId);
                    }
                    setTimeout(this.initPage,10);
                }
            }).finally(()=>{
                this.saveAddBtnLoading = false;
            });
        },
        reTranslateEvent(row){
            this.$emit('reTranslate',row);
        },
        saveEditEvent(scope){
            let index=scope.$index;
            let row=scope.row;
            let url=termApi.saveEditTerm;
             if(!this.editOriginalValue){
                this.$message.error(this.$t('component.JargonDialog_term_source_verification_message'));
                return;
            }
            if(!this.editTargetValue){
                this.$message.error(this.$t('component.JargonDialog_term_target_verification_message'));
                return;
            }
            let params={
                termDetailId:row.termDetailId,
                source:this.editOriginalValue,
                target:this.editTargetValue,
                description: this.editRemarkValue,
            };
            this.saveEditBtnLoading = true;
            this.$ajax.put(url,params).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('component.JargonDialog_save_success_message'));
                    this.editOriginalValue = '';
                    this.editTargetValue = '';
                    this.editRemarkValue = '';
                    this.tableData[index]['edit'] = false;
                    this.initPage();
                }
            }).finally(()=>{
                this.saveEditBtnLoading = false;
            });
            
        },
        deleteEvent(row){
            let url = termApi.batchDeleteTerm;
            this.$ajax.post(url,[row.termDetailId]).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('component.JargonDialog_delete_success_message'));
                    if(this.tableData.length === 2 && this.pageNumber > 1){
                        this.pageNumber = this.pageNumber - 1;
                    }
                    this.initPage();
                }
            })
        },
        setShow(show){
            this.show=show;
            if(show){
                if(this.termId){
                    this.uploadData = {
                        termId:this.termId
                    }
                }else{
                    if(this.language){
                        this.uploadData = {
                            sourceLang: this.language.source,
                            targetLang: this.language.target
                        }
                    }
                }
                this.pageNumber = 1;
                this.initPage();
            }
        }
    },
    mounted() {
        // this.$store.dispatch('common/initUploadTokenAction');
        // this.initPage();
    },

}
</script>
<style lang="scss" scoped>
@import '../assets/css/variables.scss';
.jargon-dialog-compontent{
    ::v-deep .el-dialog__body{
        padding: 0;
    }
    .mian-table-content{
        padding: 20px 40px;
    }
    .search-input{
        // padding: 0 199px;
        // margin-bottom: 20px;
        ::v-deep .el-input__inner{
            border-radius: 50px;
        }

    }
    .table-content{
        margin-bottom: 40px;
        .el-button--text{
            color: $font-primary-color;
            &:hover,&foucs{
                color: $font-primary-color-hover;
            }
            &.delete-btn{
                color: $font-danger-color;
                &:hover,&foucs{
                    color: $font-danger-color-hover;
                }
            }
        }
    }
    .btn-content{
        > div{
            display: inline-block;
            margin-right:20px;
        }
    }


}
</style>
